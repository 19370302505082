import React from 'react';
import PlatformCard from './PlatformCard';

const platforms = [
  {
    name: "Fantasy Premier League",
    description: "Fantasy Premier League (FPL) is widely regarded as the most popular fantasy football game in the world, with millions of players participating each season. As the official fantasy game of the English Premier League, FPL offers an immersive experience for soccer fans by allowing them to create their own team using real Premier League players. The season-long format requires managers to think strategically, as weekly transfers, player injuries, and match schedules can all affect their chances of winning. FPL provides a wealth of data and statistics, enabling players to make informed decisions about their team lineup each week. The platform also boasts an active online community where users discuss strategies, analyze player performance, and share insights. FPL is a perfect choice for anyone who wants to engage more deeply with the Premier League and test their managerial skills in a competitive but friendly environment.",
    link: "https://fantasy.premierleague.com/",
  },
  {
    name: "ESPN Fantasy Sports",
    description: "ESPN Fantasy Sports is a comprehensive fantasy platform that offers games for several major sports, including football, basketball, baseball, and hockey. Known for its in-depth analysis and expert commentary, ESPN Fantasy provides participants with all the tools they need to succeed in their fantasy leagues. The platform includes features like trade assistance, customizable league settings, and live injury updates, which can help players make strategic decisions throughout the season. ESPN’s fantasy platform is also highly flexible, with options for both daily and season-long formats, catering to a range of player preferences. The robust analytics, coupled with ESPN's reputation for sports expertise, makes this platform a great choice for both beginners and seasoned fantasy sports enthusiasts.",
    link: "https://www.espn.com/fantasy/",
  },
  {
    name: "Yahoo Fantasy Sports",
    description: "Yahoo Fantasy Sports covers a wide variety of sports, including football, basketball, baseball, and hockey, making it one of the most versatile fantasy platforms available. Known for its intuitive mobile app, Yahoo Fantasy allows players to stay updated on their teams and leagues from anywhere. The platform offers real-time scoring, customizable league settings, and a range of draft options, including snake and auction drafts. Yahoo Fantasy is suitable for all skill levels, with beginner-friendly settings and expert advice available for those looking to take their gameplay to the next level. The platform also offers a vibrant community and regular updates, ensuring a dynamic fantasy experience.",
    link: "https://football.fantasysports.yahoo.com/",
  },
  {
    name: "FanDuel",
    description: "FanDuel is a leading daily fantasy sports (DFS) platform known for its fast-paced, single-day fantasy sports contests. Players draft new teams for each contest, focusing on short-term performance rather than season-long commitment. FanDuel uses a salary cap format where each player has a set price, and participants must build a team within a budget. This DFS format is particularly popular among players who prefer a quick turnaround and immediate results. FanDuel also offers significant prize pools and frequently hosts promotional events, making it an attractive option for players looking to test their skills and potentially earn rewards. The platform is easy to use and appeals to both casual fans and seasoned DFS players.",
    link: "https://www.fanduel.com/",
  },
  {
    name: "DraftKings",
    description: "DraftKings is a dominant player in the daily fantasy sports (DFS) market, providing contests in a variety of sports, including football, basketball, golf, and more. DraftKings’ innovative platform allows participants to join contests with different formats, including head-to-head matchups, 50/50 contests, and large tournaments with significant prize pools. Like FanDuel, DraftKings uses a salary cap draft system, adding an extra layer of strategy as players must choose their roster within budget constraints. The platform is highly regarded for its user experience and detailed statistics, making it a favorite among serious DFS players. DraftKings also has a vibrant community, where players can share strategies and improve their skills.",
    link: "https://www.draftkings.com/",
  },
  {
    name: "NFL Fantasy Football",
    description: "NFL Fantasy Football is the official fantasy football platform of the National Football League, tailored specifically for fans of American football. The platform provides comprehensive stats, matchup insights, and expert analysis to help participants build strong teams and compete against others. With unique features like real-time stat tracking and player projections, NFL Fantasy is ideal for die-hard NFL fans. The platform allows for various draft options and customizable league settings, catering to different player preferences. It’s a great choice for fans looking to follow the NFL season closely and immerse themselves in the world of American football.",
    link: "https://fantasy.nfl.com/",
  },
  {
    name: "CBS Sports Fantasy",
    description: "CBS Sports Fantasy is a well-respected platform that offers a variety of fantasy games across sports like football, baseball, and basketball. It is known for its detailed statistics, real-time updates, and expert advice, making it a solid choice for competitive leagues. CBS Sports provides an intuitive drafting interface, advanced trade insights, and personalized player projections. The platform supports both season-long and daily fantasy formats, giving participants the flexibility to choose their preferred competition style. With an active community and strong analytical tools, CBS Sports Fantasy appeals to both new and experienced players looking to engage with sports on a deeper level.",
    link: "https://www.cbssports.com/fantasy/",
  },
  {
    name: "Fantrax",
    description: "Fantrax is one of the most customizable fantasy sports platforms available, supporting a wide range of sports, from mainstream leagues to niche options. Known for its flexibility, Fantrax allows users to customize nearly every aspect of their league, including scoring settings, roster configurations, and draft formats. It supports daily, season-long, and dynasty leagues, appealing to hardcore fantasy players who want full control over their league settings. Fantrax is ideal for fantasy sports enthusiasts who value customization and want to create unique league experiences tailored to their group’s preferences.",
    link: "https://www.fantrax.com/",
  },
  {
    name: "Sleeper",
    description: "Sleeper is a popular platform for fantasy football that emphasizes the social and interactive aspects of fantasy sports. With a user-friendly app and integrated chat features, Sleeper enables players to create leagues, draft players, and stay connected throughout the season. The platform is designed to be accessible for all players, providing a fun and engaging environment for both beginners and experienced fantasy players. Sleeper’s focus on social interaction and its modern design make it an appealing choice for friends or family groups who want a lively and enjoyable fantasy football experience.",
    link: "https://sleeper.com/",
  },
  {
    name: "RealFevr",
    description: "RealFevr is a global fantasy sports platform that focuses on football (soccer) and offers a unique experience with its combination of classic fantasy leagues and card-based competitions. RealFevr includes live stats, real-time updates, and an active community of soccer enthusiasts. The platform’s game modes and customization options make it ideal for both casual and serious soccer fans who want an engaging and immersive fantasy experience. RealFevr’s emphasis on soccer sets it apart from other platforms, making it the go-to choice for soccer fans around the world.",
    link: "https://www.realfevr.com/",
  }
];

const PlatformList = () => (
  <section className="platform-list">
    {platforms.map((platform, index) => (
      <div key={index} className="platform-entry">
        <h3>{platform.name}</h3>
        <p>{platform.description}</p>
        <a href={platform.link} target="_blank" rel="noopener noreferrer" className="visit-button">Visit Now</a>
      </div>
    ))}
  </section>
);

export default PlatformList;
