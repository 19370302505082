import React from 'react';

const AboutSection = () => (
  <section className="about">
    <h2>About Fantasy Sports</h2>
    <p>Fantasy sports allow fans to experience sports on a new level by creating virtual teams composed of real-life players. Each player's performance in actual games translates into points in the fantasy game, rewarding players with a mix of strategy, knowledge, and luck. Fantasy sports platforms are available for a range of sports including football, basketball, baseball, and more.</p>
    <p>Compete against friends, join public leagues, or challenge others in daily contests. With platforms offering detailed statistics, live updates, and real-time scoring, fantasy sports are a thrilling way to engage with your favorite games. Whether you're a seasoned player or a newcomer, there's a platform here for everyone.</p>
  </section>
);

export default AboutSection;
