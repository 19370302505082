import React from 'react';

const IntroductionSection = () => (
  <section className="introduction">
    <h1>Welcome to the World of Fantasy Sports</h1>
    <p>Fantasy sports offer a captivating blend of strategy, knowledge, and excitement for fans of all ages. These games allow participants to create virtual teams using real-life players from professional sports leagues. The performance of each player in actual games directly impacts the points accumulated by a fantasy team, making it a dynamic experience that brings fans closer to the action than ever before.</p>

    <h2>Origins of Fantasy Sports</h2>
    <p>The concept of fantasy sports began in the early 1960s in the United States with a group of baseball fans who wanted a way to make watching games more interactive and engaging. These enthusiasts created a game that assigned points to players based on their real-life performance in baseball games. The idea quickly gained traction among sports fans, becoming a favorite pastime in sports clubs and friend circles.</p>

    <p>This early form of fantasy sports was known as “Rotisserie League Baseball,” named after the restaurant where the game was first devised. Participants would draft players before the season, using statistics from actual games to score points. As baseball evolved, so did fantasy sports, eventually moving beyond baseball to include other major American sports like football, basketball, and hockey.</p>

    <p>The internet revolution in the 1990s transformed fantasy sports, making it accessible to millions of fans worldwide. Today, fantasy sports include nearly every popular sport: football (soccer), basketball, baseball, cricket, and even esports. Fantasy sports have grown into a massive industry, with millions of participants competing in various leagues, from season-long formats to daily contests.</p>

    <h2>How Fantasy Sports Work</h2>
    <p>Fantasy sports involve participants acting as team managers. They draft a lineup of real-life players from professional leagues to create their fantasy teams. Once the teams are formed, points are awarded based on the players’ performances in actual games. For example, if a football player scores a goal, the fantasy manager with that player in their team receives corresponding points. This scoring continues throughout the season, with managers competing to amass the most points.</p>

    <h2>Types of Fantasy Sports Games</h2>
    <p>There are several formats for playing fantasy sports, each with unique challenges and rewards:</p>
    <ul>
      <li><strong>Season-Long Leagues:</strong> These leagues run for the duration of a sports season. Players draft their teams at the beginning of the season and make changes each week based on injuries, trades, or performance. Managers compete for the highest total score or weekly victories over other teams in the league.</li>
      <li><strong>Daily Fantasy Sports (DFS):</strong> Platforms like DraftKings and FanDuel offer DFS formats, allowing players to draft teams for a single game day. This faster-paced format adds a different layer of excitement, as managers create teams with new players every day, based on matchups and salary caps.</li>
    </ul>

    <h2>The Appeal of Fantasy Sports</h2>
    <p>Fantasy sports provide a deeper connection to the sports world. Fans gain insights into player statistics, team dynamics, and match strategies. Many players find that fantasy sports improve their understanding and appreciation of the game, enhancing their engagement with both individual athletes and teams.</p>

    <h2>Pros and Cons of Fantasy Sports</h2>
    <p>One of the main advantages of fantasy sports is the sense of community and friendly competition it fosters. Playing in leagues with friends, family, or coworkers allows fans to share their passion and even add stakes to games they would otherwise watch passively. The strategy involved in fantasy sports sharpens analytical skills and sports knowledge.</p>
    
    <p>On the downside, fantasy sports can be time-consuming, especially in season-long leagues, where keeping up with injuries, trades, and game schedules is necessary to stay competitive. Additionally, daily fantasy sports often involve cash prizes, which may encourage risk-taking behavior.</p>
    
    <p>Whether you’re a casual fan or a die-hard sports enthusiast, fantasy sports provide an enjoyable, competitive way to stay connected with the sports you love. Explore our platform recommendations to start your fantasy sports journey today!</p>
  </section>
);

export default IntroductionSection;
