import React from 'react';
import './Header.css';

const Header = () => (
  <header className="header">
    <div className="header-logo">
      <h1>fantasysportworld24.com</h1>
    </div>
    <nav className="header-nav">
      <a href="/">Home</a>
      <a href="/about">About</a>
      <a href="/contact">Contact</a>
    </nav>
  </header>
);

export default Header;
