import React from 'react';
import './Footer.css';

const Footer = () => (
  <footer className="footer">
    <div className="footer-content">
      <h2>fantasysportworld24.com</h2>
      <p>Welcome to FantasySportWorld24, your ultimate destination for everything fantasy sports. Our mission is to provide you with a comprehensive platform where fantasy sports enthusiasts from around the globe can come together, discover top platforms, and dive deep into the world of fantasy sports.</p>
      <p>Fantasy sports have evolved from a casual hobby to a vibrant, global community where strategy, knowledge, and sportsmanship come together. At FantasySportWorld24, we aim to guide you through this exciting universe by offering insights into the best fantasy sports platforms available, from Fantasy Premier League and ESPN Fantasy Sports to FanDuel and DraftKings. Whether you're a beginner just starting your fantasy journey or an experienced player looking to fine-tune your strategies, we’ve got something for everyone.</p>
      <p>Our site offers detailed reviews, tips, and guides for various fantasy sports platforms, helping you make informed choices that suit your style and preferences. We focus on major sports like football, basketball, baseball, and soccer, ensuring that no matter your interest, you’ll find the right fantasy platform to match your passion.</p>
      <p>At FantasySportWorld24, we believe in the power of community. Fantasy sports are not just about competition; they’re about connecting with friends, family, and fans from all over the world. Our platform fosters an inclusive environment where users can share insights, discuss strategies, and enjoy the thrill of fantasy sports together. We provide valuable resources, from tips on choosing players to understanding scoring systems, making it easier for you to get the most out of your fantasy sports experience.</p>
      <p>As fantasy sports continue to grow, FantasySportWorld24 is committed to staying up-to-date with the latest trends, rules, and strategies in the industry. Our goal is to be a reliable resource that you can turn to for everything related to fantasy sports, helping you improve your game and stay ahead of the competition. Join us, explore the world of fantasy sports, and become part of a community that celebrates the thrill of sports in a unique and engaging way.</p>

      <p>Contact us at <a href="mailto:info@fantasysportworld24.com">info@fantasysportworld24.com</a> for any inquiries or support. Let’s make your fantasy sports experience extraordinary!</p>

      <div className="footer-links">
        <a href="/privacy-policy">Privacy Policy</a> | 
        <a href="/terms-conditions">Terms & Conditions</a> | 
        <a href="/cookie-policy">Cookie Policy</a>
      </div>

      <form className="contact-form">
        <h3>Contact Us</h3>
        <input type="text" placeholder="Your Name" required />
        <input type="email" placeholder="Your Email" required />
        <textarea placeholder="Your Message" required></textarea>
        <button type="submit">Send</button>
      </form>
    </div>
  </footer>
);

export default Footer;
